<template>
  <div class="main-content">
    <b-card>
      <good-data-table 
        ref="leaves" 
        :columns="columns" :no-serial="true"
        :total-column="'response.responseData.total'" 
        :data-column="'response.responseData.data'"
        :api-endpoint="'/hrm/leave'"
        @add-new-info="New_Leave" 
        :buttonLabel="'Add'"
      />
    </b-card>
    <validation-observer ref="Create_Leave">
      <b-modal hide-footer size="lg" id="New_Modal_Leave" :title="editmode?$t('Edit'):$t('Add')">
        <b-form @submit.prevent="Submit_Leave">
          <b-row>
                <!-- Department -->
                <b-col md="6">
                  <validation-provider name="Department" :rules="{ required: true}">
                    <b-form-group slot-scope="{ valid, errors }" :label="$t('Department') + ' ' + '*'">
                      <v-select
                        :class="{'is-invalid': !!errors.length}"
                        :state="errors[0] ? false : (valid ? true : null)"
                        v-model="leave.department_id"
                        class="required"
                        required
                        @input="Selected_Department"
                        :placeholder="$t('Department')"
                        :reduce="label => label.value"
                        :options="departments.map(departments => ({label: departments.department, value: departments.id}))"
                      />
                      <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                  <!-- Employee -->
                <b-col md="6">
                  <validation-provider name="Employee" :rules="{ required: true}">
                    <b-form-group slot-scope="{ valid, errors }" :label="$t('Employee') + ' ' + '*'">
                      <v-select
                        :class="{'is-invalid': !!errors.length}"
                        :state="errors[0] ? false : (valid ? true : null)"
                        v-model="leave.employee_id"
                        class="required"
                        required
                        @input="Selected_Employee"
                        :placeholder="$t('Choose Employee')"
                        :reduce="label => label.value"
                        :options="employees.map(employees => ({label: employees.username, value: employees.id}))"
                      />
                      <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                   <!-- leave_type -->
                <b-col md="6">
                  <validation-provider name="leave_type" :rules="{ required: true}">
                    <b-form-group slot-scope="{ valid, errors }" :label="$t('Leave Type') + ' ' + '*'">
                      <v-select
                        :class="{'is-invalid': !!errors.length}"
                        :state="errors[0] ? false : (valid ? true : null)"
                        v-model="leave.leave_type_id"
                        class="required"
                        required
                        @input="Selected_Leave_Type"
                        :placeholder="$t('Choose leave type')"
                        :reduce="label => label.value"
                        :options="leave_types.map(leave_types => ({label: leave_types.title, value: leave_types.id}))"
                      />
                      <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>

              <!-- start date -->
              <b-col md="6">
                <validation-provider
                  name="start_date"
                  :rules="{ required: true}"
                  v-slot="validationContext"
                >
                    <b-form-group :label="$t('start date') + ' ' + '*'">
                        <Datepicker id="start_date" name="start_date" :placeholder="$t('Enter Start date')" v-model="leave.start_date" 
                            input-class="form-control back_important" format="yyyy-MM-dd"  @closed="leave.start_date=formatDate(leave.start_date)">
                        </Datepicker>
                        <b-form-invalid-feedback id="start_date-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                     </b-form-group>
                </validation-provider>
              </b-col>

               <!-- end date -->
              <b-col md="6">
                <validation-provider
                  name="Finish_Date"
                  :rules="{ required: true}"
                  v-slot="validationContext"
                >
                    <b-form-group :label="$t('Finish Date')">
                        <Datepicker id="end_date" name="end_date" :placeholder="$t('Enter Finish date')" v-model="leave.end_date" 
                            input-class="form-control back_important" format="yyyy-MM-dd"  @closed="leave.end_date=formatDate(leave.end_date)">
                        </Datepicker>
                        <b-form-invalid-feedback id="end_date-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                     </b-form-group>
                </validation-provider>
              </b-col>

                <!-- Status -->
                <b-col lg="6" md="6" sm="12" class="mb-2">
                  <validation-provider name="Status" :rules="{ required: true}">
                    <b-form-group slot-scope="{ valid, errors }" :label="$t('Status') + ' ' + '*'">
                      <v-select
                        :class="{'is-invalid': !!errors.length}"
                        :state="errors[0] ? false : (valid ? true : null)"
                        v-model="leave.status"
                        :reduce="label => label.value"
                        :placeholder="$t('Choose status')"
                        :options="
                          [
                            {label: 'Approved', value: 'approved'},
                            {label: 'Pending', value: 'pending'},
                            {label: 'Rejected', value: 'rejected'},
                          ]"
                      ></v-select>
                      <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>

            <!-- -Attachment -->
            <b-col md="6">
              <validation-provider name="Attachment" ref="Attachment" rules="mimes:image/*|size:2048">
                <b-form-group slot-scope="{validate, valid, errors }" :label="$t('Attachment')">
                  <input
                    :state="errors[0] ? false : (valid ? true : null)"
                    :class="{'is-invalid': !!errors.length}"
                    @change="changeAttachement"
                    label="Choose Attachment"
                    type="file"
                  >
                  <b-form-invalid-feedback id="Attachment-feedback">{{ errors[0] }}</b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>


              <!-- Leave_Reason -->
            <b-col md="12">
                <b-form-group :label="$t('Leave Reason')">
                  <b-form-textarea
                    rows="3"
                    :placeholder="$t('Enter Reason Leave')"
                    label="Leave Reason"
                    v-model="leave.reason"
                  ></b-form-textarea>
                </b-form-group>
            </b-col>


            <b-col md="12" class="mt-3">
                <b-button variant="primary" type="submit"  :disabled="SubmitProcessing"><i class="i-Yes me-2 font-weight-bold"></i> {{$t('submit')}}</b-button>
                  <div v-once class="typo__p" v-if="SubmitProcessing">
                    <div class="spinner sm spinner-primary mt-3"></div>
                  </div>
            </b-col>

          </b-row>
        </b-form>
      </b-modal>
    </validation-observer>
  </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker';
import GoodDataTable from '@/@core/layouts/shared/GoodDataTable.vue'
import Vue from 'vue'
import axios from 'axios'
import jwtDefaultConfig from '@/@core/auth/jwt/jwtDefaultConfig'
const Fire = new Vue();
const apiUrl = `${jwtDefaultConfig.apiEndpoint}/hrm`;
import { hideLoader, showErrorNotification, showLoader, showSuccessNotification} from '@/@core/comp-functions/ui/app'

export default {
  metaInfo: {
    title: "Leave"
  },
   components: {
    Datepicker,
    GoodDataTable
  },
  data() {
    return {
      isLoading: true,
      SubmitProcessing:false,
      serverParams: {
        columnFilters: {},
        sort: {
          field: "id",
          type: "desc"
        },
        page: 1,
        perPage: 10
      },
      data: new FormData(),
      selectedIds: [],
      totalRows: "",
      search: "",
      limit: "10",
      editmode: false,
      employees:[],
      companies:[],
      departments:[],
      leave_types:[],
      leaves: [], 
      leave: {
          department_id: "",
          employee_id: "",
          leave_type_id :"",
          start_date:"",
          end_date:"",
          days:"",
          reason:"",
          attachment:"",
          half_day:"",
          status:"",
      }, 
    };
  },

  computed: {
    columns() {
      return [
        {
          label: this.$t("Employee"),
          field: "employee_name",
          
        },
        {
          label: this.$t("Department"),
          field: "department_name",
          
        },
        {
          label: this.$t("Leave Type"),
          field: "leave_type_title",
          
        },
        {
          label: this.$t("Start Date"),
          field: "start_date",
          
        },
        {
          label: this.$t("Finish Date"),
          field: "end_date",
          
        },
        {
          label: this.$t("Days"),
          field: "days",
          
        },
        {
          label: this.$t("Status"),
          field: "status",
          tdClass: 'text-capitalize'
        },
        {
          label: 'Action',
          field: 'action',
          sortable: false,
          type: 'dropdown',
          actions: [
            {
              isIcon: true,
              iconHtml: this.$helpers.getIcons('edit'),
              text: 'Edit',
              action: props => {
                this.Edit_Leave(props)
              },
            },
            {
              isIcon: true,
              iconHtml: this.$helpers.getIcons('delete'),
              text: 'Delete',
              action: props => {
                this.$swal({
                  title: this.$t('You want to delete?'),
                  iconHtml: this.$helpers.swalIcon('alert.png'),
                  showCancelButton: true,
                  confirmButtonText: this.$t('Yes'),
                  cancelButtonText: this.$t('No'),
                  customClass: {
                    confirmButton: 'btn btn-success',
                    cancelButton: 'btn btn-danger ml-1',
                    icon: 'border-0',
                  },
                  buttonsStyling: false,
                }).then(result => {
                  if (result.value) {
                    this.Remove_Leave(props)
                  }
                })
              },
            },
          ],
        },
      ];
    }
  },

  methods: {
    //---- update Params Table
    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },

    //---- Event Page Change
    onPageChange({ currentPage }) {
      if (this.serverParams.page !== currentPage) {
        this.updateParams({ page: currentPage });
        this.Get_Leaves(currentPage);
      }
    },

    //---- Event Per Page Change
    onPerPageChange({ currentPerPage }) {
      if (this.limit !== currentPerPage) {
        this.limit = currentPerPage;
        this.updateParams({ page: 1, perPage: currentPerPage });
        this.Get_Leaves(1);
      }
    },

    //---- Event Select Rows
    selectionChanged({ selectedRows }) {
      this.selectedIds = [];
      selectedRows.forEach((row, index) => {
        this.selectedIds.push(row.id);
      });
    },

    //---- Event Sort Change

    onSortChange(params) {
      let field = "";
      if (params[0].field == "department_name") {
        field = "department_id";
      } else if (params[0].field == "employee_name") {
        field = "employee_id";
      }else if (params[0].field == "leave_type_title") {
        field = "leave_type_id";
      }else {
        field = params[0].field;
      }
      this.updateParams({
        sort: {
          type: params[0].type,
          field: params[0].field
        }
      });
      this.Get_Leaves(this.serverParams.page);
    },

    //---- Event Search
    onSearch(value) {
      this.search = value.searchTerm;
      this.Get_Leaves(this.serverParams.page);
    },

    //---- Validation State Form
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },

    formatDate(d){
        var m1 = d.getMonth()+1;
        var m2 = m1 < 10 ? '0' + m1 : m1;
        var d1 = d.getDate();
        var d2 = d1 < 10 ? '0' + d1 : d1;
        return [d.getFullYear(), m2, d2].join('-');
    },

       //------------------------------ Event Upload attachment -------------------------------\\
    async changeAttachement(e) {
      const { valid } = await this.$refs.Attachment.validate(e);

      if (valid) {
        this.leave.attachment = e.target.files[0];
      } else {
        this.leave.attachment = "";
      }
    },

    //------------- Submit Validation Create & Edit Leave
    Submit_Leave() {
      this.$refs.Create_Leave.validate().then(success => {
        if (!success) {
          showErrorNotification(this, 'Please fill the form correctly');
        } else {
          if (!this.editmode) {
            this.Create_Leave();
          } else {
            this.Update_Leave();
          }
        }
      });
    },

    //------------------------------ Modal (create Leave) -------------------------------\\
    New_Leave() {
      this.reset_Form();
      this.editmode = false;
      this.Get_Data_Create();
      
    },

   //------------------------------ Show Modal (Update Leave) -------------------------------\\
    Edit_Leave(leave) {
        this.editmode = true;
        this.reset_Form();
        this.Get_Data_Edit(leave.id);
    },

     
     Selected_Employee(value) {
                if (value === null) {
                    this.leave.employee_id = "";
                }
            },

            Selected_Leave_Type(value) {
                if (value === null) {
                    this.leave.leave_type_id = "";
                }
            },

            Selected_Status(value) {
                if (value === null) {
                    this.leave.status = "";
                }
            },

            Selected_Company(value) {
                this.employees = [];
                this.departments = [];
                this.leave.employee_id = "";
                this.leave.department_id = "";
                this.Get_departments_by_company();
            },

            Selected_Department(value) {
                if (value === null) {
                    this.leave.department_id = "";
                    this.leave.employee_id = "";
                }
                this.employee_id = [];
                this.leave.employee_id = "";
                this.Get_employees_by_department(value);
            },

       
              //---------------------- Get_departments_by_company ------------------------------\\
            Get_departments_by_company(value) {
            axios
                .get(apiUrl+"/core/get_departments_by_company")
                .then(({ data }) => (this.departments = data));
            },

            //---------------------- Get_employees_by_department ------------------------------\\
            
            Get_employees_by_department(value) {
                axios
                .get(apiUrl+"/get_employees_by_department?id=" + value)
                .then(({ data }) => (this.employees = data));
            },


            
             //---------------------- Get_Data_Create  ------------------------------\\
             Get_Data_Create() {
                axios
                    .get(apiUrl+"/leave/create")
                    .then(response => {
                        this.companies   = response.data.companies;
                        this.leave_types = response.data.leave_types;
                        this.$bvModal.show("New_Modal_Leave");
                    })
                    .catch(error => {
                       
                    });
            },

              //---------------------- Get_Data_Edit  ------------------------------\\
              Get_Data_Edit(id) {
                axios
                    .get(`${apiUrl}/leave/${id}/edit`)
                    .then(response => {
                        this.leave    = response.data.leave;
                        this.companies   = response.data.companies;
                        this.leave_types = response.data.leave_types;
                        this.Get_departments_by_company();
                        this.Get_employees_by_department(this.leave.department_id);
                        this.leave.attachment = "";
                        this.$bvModal.show("New_Modal_Leave");
                    })
                    .catch(error => {
                       
                    });
            },


    //--------------------------Get ALL Leaves ---------------------------\\

    Get_Leaves(page) {
      // Start the progress bar.
      axios
        .get(
          apiUrl+"/leave?page=" +
            page +
            "&SortField=" +
            this.serverParams.sort.field +
            "&SortType=" +
            this.serverParams.sort.type +
            "&search=" +
            this.search +
            "&limit=" +
            this.limit
        )
        .then(response => {
          this.totalRows = response.data.totalRows;
          this.leaves = response.data.leaves;

          // Complete the animation of theprogress bar.
          this.isLoading = false;
        })
        .catch(response => {
          // Complete the animation of theprogress bar.
          setTimeout(() => {
            this.isLoading = false;
          }, 500);
        });
    },

    //------------------------------- Create leave ------------------------\\
    Create_Leave() {
      
      var self = this;
        self.SubmitProcessing = true;
        self.data.append("department_id", self.leave.department_id);
        self.data.append("employee_id", self.leave.employee_id);
        self.data.append("leave_type_id", self.leave.leave_type_id);
        self.data.append("start_date", self.leave.start_date);
        self.data.append("end_date", self.leave.end_date);
        self.data.append("reason", self.leave.reason);
        self.data.append("attachment", self.leave.attachment);
        self.data.append("half_day", self.leave.half_day?1:0);
        self.data.append("status", self.leave.status);

      axios.post(apiUrl+"/leave", self.data)
        .then(response => {
          if(response.data.isvalid == false){
              self.SubmitProcessing = false;
              self.errors = {};
              showErrorNotification(this, "Remaining leaves are insufficient")
          }
          else{
            this.SubmitProcessing = false;
            Fire.$emit("Event_Leave");
            showSuccessNotification(this, 'Created successfully!');
          }
        })
        .catch(error => {
          this.SubmitProcessing = false;
          showErrorNotification(this, "Invalid Data")
        });
    },

    //------------------------------- Update Leave ------------------------\\
    Update_Leave() {

      var self = this;
      self.SubmitProcessing = true;
      self.data.append("department_id", self.leave.department_id);
      self.data.append("employee_id", self.leave.employee_id);
      self.data.append("leave_type_id", self.leave.leave_type_id);
      self.data.append("start_date", self.leave.start_date);
      self.data.append("end_date", self.leave.end_date);
      self.data.append("reason", self.leave.reason);
      self.data.append("attachment", self.leave.attachment);
      self.data.append("half_day", self.leave.half_day?1:0);
      self.data.append("status", self.leave.status);
      self.data.append("_method", "put");

       axios.post(apiUrl+"/leave/" + self.leave.id, self.data)
        .then(response => {
           if(response.data.isvalid == false){
              self.SubmitProcessing = false;
              self.errors = {};
              showErrorNotification(this, "Remaining leaves are insufficient")
          }
          else{
            this.SubmitProcessing = false;
            Fire.$emit("Event_Leave");
            showSuccessNotification(this, 'Updated successfully!');
          }
        })
        .catch(error => {
          this.SubmitProcessing = false;
          showErrorNotification(this, "Invalid Data")
        });
    },

    //------------------------------- reset Form ------------------------\\
    reset_Form() {
     this.leave = {
        id: "",
        department_id: "",
        employee_id: "",
        leave_type_id :"",
        start_date:"",
        end_date:"",
        days:"",
        reason:"",
        attachment:"",
        half_day:"",
        status:"",
      };
    },

    //------------------------------- Delete Leave ------------------------\\
    Remove_Leave(props) {
      axios
        .delete(apiUrl+"/leave/" + props.id)
        .then(() => {
          showSuccessNotification(this, 'Deleted successfully!');

          Fire.$emit("Delete_Leave");
        })
        .catch(() => {
          showErrorNotification(this, "Something went wrong while deleting item!");
        });
    },

    //---- Delete department by selection

    delete_by_selected() {
      this.$swal({
        text: '',
        title: this.$t('Please confirm you want to delete this item?'),
        iconHtml: this.$helpers.swalIcon('alert.png'),
        showCancelButton: true,
        confirmButtonText: this.$t('Yes'),
        cancelButtonText: this.$t('No'),
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger ml-1',
          icon: 'border-0',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          // Start the progress bar.
          axios
            .post(apiUrl+"/leave/delete/by_selection", {
              selectedIds: this.selectedIds
            })
            .then(() => {
              showSuccessNotification(this, 'Deleted successfully!');

              Fire.$emit("Delete_Leave");
            })
            .catch(() => {
              // Complete the animation of theprogress bar.
              showErrorNotification(this, "Something went wrong while deleting item!");
            });
        }
      });
    }
  },

  //----------------------------- Created function-------------------\\

  mounted: function() {
    this.$refs.leaves.loadItems();
    this.Get_departments_by_company();
    Fire.$on("Event_Leave", () => {
      setTimeout(() => {
        this.$refs.leaves.loadItems();
        this.$bvModal.hide("New_Modal_Leave");
      }, 500);
    });
    
    Fire.$on("Delete_Leave", () => {
      setTimeout(() => {
        this.$refs.leaves.loadItems();
      }, 500);
    });
  }
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
